import React from "react"
/** @jsx jsx */
import { jsx, css } from "@emotion/react"
import styled from "@emotion/styled"
import GreenCheckmarkSVG from "../../assets/images/green-checkmark.svg"
import { Link } from "react-router-dom"
import {
  SharedListElement,
  SharedDisorderBackground,
  SharedDisorderSimpleText,
  SharedDisorderHeaderText,
} from "../../utils/shared"
import AddIcon from "../../assets/images/add-chips-btn.svg"
import Verified from "../../assets/images/verified.png"
import axios from "axios"
import { useState } from "react"
import { baseUrl } from "../../lib/constants"
import '../../css/papInnerBlock.scss';
import ReactLinkify from "react-linkify"
import logClickEvent from "../../utils/logClickEvent"


export const DisorderList = ({
  lang,
  list,
  caption,
  handleTherapyModalClick,
  setDefaultTasks,
  defaultTasks = [],
  fromPAP
}) => {

  const [addTaskLoading, setAddTaskLoading] = useState(null)

  const handleAddTask = async (i, index, type) => {
    setAddTaskLoading(index)
    let title = {}
    if (type == 'support') { title = i[fromPAP ? 'title' : 'text'] } else { title = i.text }
    // const title = type == 'support'? i[fromPAP ? 'title' : 'text'] : i.text
    try {
      const response = await axios.post(`${baseUrl}/plans-goals/goal-default`, {
        title: title,
        type: type
      }, {
        withCredentials: true,
        mode: "cors",
      })
      setDefaultTasks(response.data)
    } catch (e) {
      console.log(e)
    } finally {
      setAddTaskLoading(null)
    }

  }

  return (
    <SharedDisorderBackground style={{ position: "relative" }} className="without-color">
      <SharedDisorderHeaderText className="green-block-heading">{caption}</SharedDisorderHeaderText>
      <div
        css={css`
        display: grid;
        grid-template-columns: 1fr;
        grid-row-gap: 0.6em;
        word-break: break-word;
      `}
        className={(caption === 'Symptoms' || caption === 'Symptômes') && list[0]['object'] ? '' : "green-block"}
      >

        {list.map((i, index) => {
          if (caption === "Therapy" || caption === "Thérapie") {
            return (
              <div className="summary-list-container" >
                <div className="w-100">
                 <SharedListElement key={`${caption}${i.text ? i.text[lang] : i.title[lang]}${index}`}>
                    <img src={GreenCheckmarkSVG} />
                    <a
                      style={{ marginLeft: '.6em' }}
                      className="therapy-education-links"
                      data-toggle="modal" data-target="#therapyModal"
                      onClick={() => {
                        logClickEvent('Total # Clicks on links in Therapy Section', 8, i.text ? i.text[lang] : i.title[lang])
                        handleTherapyModalClick(i.text ? i.text['en'] : i.title['en'])
                      }}
                    >
                      {i.text ? i.text[lang] : i.title[lang]}
                    </a>
                  </SharedListElement> 
                </div>

              </div>
            )
          } else if (caption == 'Soutien' || caption == 'Support') {
            return <SharedListElement
              onClick={() => logClickEvent('Total # Clicks on links in Support Section', 8, i.text ? i.text[lang] : i.title[lang])}
              key={`${caption}${i['title'] ? i['title'][lang] : (i['text'] ? i['text'][lang] : '')}${index}`}
            >
              <img src={GreenCheckmarkSVG} />
              <SharedDisorderSimpleText>
                {i.link ?
                  <a className="therapy-education-links" target="_blank" href={i.link}>
                    {i.text ? i.text[lang] : i.title[lang]}
                  </a>

                  :
                  i.text ? i.text[lang] : i.title[lang]
                }
              </SharedDisorderSimpleText>
              <div className="summary-list-container" >
                {/* {console.log('disorder tasks ----->', defaultTasks)} */}
                {/* {addTaskLoading == index ?
                  <i class="fas fa-circle-notch fa-spin loader-part"></i>
                  :
                  !defaultTasks.length ?
                    <button onClick={() => handleAddTask(i, index, 'support')} className="summary-add-icon">
                      <img src={AddIcon} alt="add-icon" />
                    </button>
                    :
                    (
                      defaultTasks.find((task) => {
                        return task?.title?.en == i[fromPAP ? 'title' : 'text']?.en
                      })
                        ?
                        <div className="verified-icon">
                          <img src={Verified} alt="verified" />
                        </div>
                        :
                        <button onClick={() => handleAddTask(i, index, 'support')} className="summary-add-icon">
                          <img src={AddIcon} alt="add-icon" />
                        </button>
                    )
                } */}
              </div>
            </SharedListElement>
            // <strong>
            //   {i['title'][lang]}
            // </strong>
          } else if (caption === 'Talk Therapy' || caption === 'Thérapie par la parole') {
            var therapyData = { 'therapy': i.text ? i.text['en'] : i.title['en'] };
            return (
              <div onClick={e => e.preventDefault()}>
                <SharedListElement key={`${caption}${i.text ? i.text[lang] : i.title[lang]}${index}`}>
                  <img src={GreenCheckmarkSVG} />
                  <a
                    style={{ marginLeft: '.6em' }}
                    className="therapy-education-links"
                    data-toggle="modal" data-target="#therapyModal"
                    onClick={() => {
                      logClickEvent('Total # Clicks on links in Therapy Section', 8, i.text ? i.text[lang] : i.title[lang])
                      handleTherapyModalClick(therapyData)
                    }}
                  >
                    {i.text ? i.text[lang] : i.title[lang]}
                  </a>
                  <div className="icon-part d-flex align-items-center">
                    {/* {addTaskLoading == index ?
                      <i class="fas fa-circle-notch fa-spin"></i>
                      :
                      !defaultTasks.length ?
                        <button onClick={() => handleAddTask(i, index, "therapy")} className="summary-add-icon">
                          <img src={AddIcon} alt="add-icon" />
                        </button>
                        :
                        (
                          defaultTasks.find((task) => {

                            return task?.title?.en == i.text?.en
                          })
                            ?
                            <div className="verified-icon">
                              <img src={Verified} alt="verified" />
                            </div>
                            :
                            <button onClick={() => handleAddTask(i, index, "therapy")} className="summary-add-icon">
                              <img src={AddIcon} alt="add-icon" />
                            </button>
                        )
                    } */}
                    {/* </div> */}
                  </div>
                </SharedListElement>
              </div>
            )
          }
          else {
            // console.log('caption iiiii => ', )
            if (i['object']) {
              return <>
                <ReactLinkify>
                  <div style={{ backgroundColor: "white" }}>
                    <div className="descriptionList many-block">
                      <strong>{i['object']['title'][lang]}</strong>
                      {i['object']['list'].map((innerList) => {
                        return <SharedListElement key={`${caption}${innerList.text ? innerList.text[lang] : innerList.title[lang]}${index}`} className="pap-content-block">
                          <img src={GreenCheckmarkSVG} />
                          <SharedDisorderSimpleText>
                            {innerList.link ?
                              <a className="therapy-education-links" target="_blank" href={innerList.link}>
                                {innerList.text ? innerList.text[lang] : innerList.title[lang]}
                              </a>
                              :
                              innerList.text ? innerList.text[lang] : innerList.title[lang]
                            }
                          </SharedDisorderSimpleText>
                        </SharedListElement>
                      })}
                    </div>
                  </div>
                </ReactLinkify>
              </>
            }
            else if (i['text']) {
              return <ReactLinkify>
                <SharedListElement key={`${caption}${i.text ? i.text[lang] : i.title[lang]}${index}`}>
                  <img src={GreenCheckmarkSVG} />
                  <SharedDisorderSimpleText style={{ display: 'block' }}>
                    {i.link ?
                      <a className="therapy-education-links" target="_blank" href={i.link}>
                        {i.text ? i.text[lang] : i.title[lang]}
                      </a>
                      :
                      i.text ? i.text[lang] : i.title[lang]
                    }
                  </SharedDisorderSimpleText>
                </SharedListElement>
              </ReactLinkify>
            }
          }
        })}
      </div>
    </SharedDisorderBackground>
  )
}



